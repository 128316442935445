.icon-svg {
  width: 30px;
}

.icon-svg-sm {
  width: 20px;
}
.icon-svg-30 {
  width: 30px;
}
.icon-svg-40 {
  width: 40px;
}
.icon-svg-50 {
  width: 50px;
}

.icon-black-to-light-blue {
  filter: invert(51%) sepia(91%) saturate(400%) hue-rotate(146deg)
    brightness(85%) contrast(89%);
}

.icon-black-to-blue {
  filter: invert(15%) sepia(62%) saturate(664%) hue-rotate(155deg)
    brightness(98%) contrast(103%);
}

.icon-hover-white:hover img {
  filter: brightness(1000%);
  transition: all 200ms ease-in-out;
}

.icon-svg-fb {
  width: 50px;
}
.icon-svg-insta {
  width: 70px;
}

.icon-hover {
  opacity: 50%;
  transition: opacity 200ms ease-in-out;
  cursor: pointer;
}

.icon-hover:hover {
  opacity: 100%;
  transition: opacity 200ms ease-in-out;
}

button .img-background {
  max-width: 15em;

  filter: blur(2px) brightness(0.9);
}

.img-box-shadow {
  filter: drop-shadow(0 0 0.25rem rgba(128, 128, 128, 0.517))
    drop-shadow(0 0 1rem rgba(128, 128, 128, 0.386))
    drop-shadow(0 0 2rem rgba(128, 128, 128, 0.128));
}

.hover-yellow:hover {
  filter: invert(82%) sepia(21%) saturate(6002%) hue-rotate(349deg)
    brightness(103%) contrast(111%);
}

* {
  margin: 0;
  padding: 0;
}

.icon-list {
  width: 100%;
  max-width: 50rem;
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
}

.icon-item {
  list-style: none;
}

.icon-link {
  color: black;
}
.icon-link:hover {
  color: var(--color-red-light);
  transition: color 100ms ease-in-out;
}
.icon-link-red:hover {
  color: var(--color-red);
  transition: color 100ms ease-in-out;
}

.icon-link i {
  margin: auto;
}

.icon-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 6rem;
  height: 6rem;
  background: white;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0);
  transition: 0.3s cubic-bezier(0.95, 0.32, 0.37, 1.21);
}

.icon-link:hover::before {
  transform: scale(1);
}

.icon-item:nth-child(1) a:hover:before {
  background: var(--color-blue-light);
}

.icon-item:nth-child(2) a:hover:before {
  background: var(--color-blue-light);
}

/* Landing Image */
#landingImage {
  width: 400px;
}
@media (min-width: 390px) and (max-width: 1399px) {
  #landingImage {
    width: 500px;
  }
  #backgroundImage {
    width: 450px;
    height: 450px;
    top: -450px;
    left: 20px;
  }
  #backgroundBorderFloating {
    width: 450px;
    height: 450px;

    bottom: 1000px;
    left: -40px;
  }
}
@media (max-width: 390px) {
  #landingImage {
    width: 400px;
  }
  #backgroundImage {
    width: 350px;
    height: 350px;

    top: -350px;
    left: 20px;
  }
  #backgroundBorderFloating {
    width: 350px;
    height: 350px;

    bottom: 740px;
    left: -20px;
  }
}
@media (min-width: 1400px) {
  #landingImage {
    width: 600px;
  }
  #backgroundImage {
    width: 550px;
    height: 550px;
    top: -550px;
    left: 40px;
  }
  #backgroundBorderFloating {
    width: 550px;
    height: 550px;
    bottom: 1200px;
    left: -20px;
  }
}

#backgroundImage {
  z-index: -5;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;

  background-image: url('../assets/images/backgroundLandingImage.jpg');
}
#backgroundBorderFloating {
  z-index: -5;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;

  border: 3px solid var(--color-red-light);
  border-radius: 20px;
  background-color: transparent;
}

.backgroundBorderFloating-sm {
  width: 220px;
  height: 220px;
  z-index: -5;
  background-size: contain;
  background-repeat: no-repeat;

  border: 3px solid var(--color-red-light);
  border-radius: 20px;
  background-color: transparent;
}

.backgroundBorderFloating-sm.backgroundPosition-top-left {
  position: relative;
  left: -20px;
  top: -240px;
}

.backgroundBorderFloating-sm.backgroundPosition-bottom-left {
  position: relative;
  left: -20px;
  top: -200px;
}

.backgroundBorderFloating-sm.backgroundPosition-top-right {
  position: relative;
  right: -20px;
  top: -240px;
}

.backgroundBorderFloating-sm + p {
  margin-top: -180px;
}
#backgroundQuiSuisJe {
  background-image: url('../assets/images/backgroundQuiSuisJe.jpg');
}

.border-image {
  border-radius: 20px;
}

#filterBackgroundCardImage {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-red);
  opacity: 30%;
  width: 250px;
  height: 250px;
  border-radius: 13px;
}

#btn-filterBackgroundCardImage:hover .card > div {
  background-color: transparent;
  transition: 200ms all;
}
