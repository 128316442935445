/* Animation entrante */
.fade-in {
  animation-duration: 2s;
  animation-name: fadein;
}
.fade-in-top {
  animation-duration: 2s;
  animation-name: fadeintop;
}
.fade-in-left {
  animation-duration: 2s;
  animation-name: fadeinleft;
}
.fade-in-left {
  animation-duration: 2s;
  animation-name: fadeinright;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeintop {
  from {
    top: -50px;
    opacity: 0;
  }

  to {
    top: 0px;
    opacity: 1;
  }
}
@keyframes fadeinleft {
  from {
    left: -50px;
    opacity: 0;
  }

  to {
    left: 0px;
    opacity: 1;
  }
}
@keyframes fadeinright {
  from {
    right: -50px;
    opacity: 0;
  }

  to {
    right: 0px;
    opacity: 1;
  }
}

/* Glow */
.glow {
  transition-duration: 0.3s;
  transition-property: box-shadow;
}

.glow:hover {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3),
    0 0 20px rgba(255, 255, 255, 0.3);
}

/* Grow */

.grow {
  transition-duration: 0.3s;
  transition-property: transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.grow:hover {
  transform: scale(1.1);
}

/* effect-shine */
.effect-shine:hover {
  mask-image: linear-gradient(
    -75deg,
    rgba(0, 0, 0, 0.6) 30%,
    #000 50%,
    rgba(0, 0, 0, 0.6) 70%
  );
  mask-size: 200%;
  animation: shine 2s infinite;
}

@keyframes shine {
  from {
    mask-position: 150%;
  }

  to {
    mask-position: -50%;
  }
}

/* mask txt hover */

.link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  overflow: hidden;
}
.link:hover .link__mask {
  transform: translate3d(0, 0, 0);
}
.link:hover .link__mask:after {
  transform: translate3d(0, 0, 0);
}

.link__text {
  color: #fff;
}

.link__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
  transform: translate3d(-100%, 0, 0);
  transform-origin: left;
  overflow: hidden;
}
.link__mask:after {
  content: attr(data-text);
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--color-yellow);
  transition: inherit;
  transform: translate3d(100%, 0, 0);
  text-shadow: 0 0 10px;
}

/* mask txt hover white */

.link:hover .link__mask-white {
  transform: translate3d(0, 0, 0);
}
.link:hover .link__mask-white:after {
  transform: translate3d(0, 0, 0);
}

.link__mask-white {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
  transform: translate3d(-100%, 0, 0);
  transform-origin: left;
  overflow: hidden;
}

.link__mask-white:after {
  content: attr(data-text);
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  transition: inherit;
  transform: translate3d(100%, 0, 0);
  text-shadow: 0 0 5px;
}

.link__mask-white.span-h2 {
  font-size: 60px;
  font-family: 'Indie Bold' !important;
}

@media (max-width: 767px) {
  .link__mask-white.span-h2 {
    font-size: 40px;
    font-family: 'Indie Bold' !important;
  }
}
/* SVG Animation */

/* Grow */

.grow {
  display: inline-block;
  transition-duration: 0.3s;
  transition-property: transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.grow:hover {
  transform: scale(1.1);
}

/* Grow big */

.grow_big {
  display: inline-block;
  transition-duration: 0.3s;
  transition-property: transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.grow_big:hover {
  transform: scale(1.5);
}

/* Glow */
.glow {
  display: inline-block;
  transition-duration: 0.3s;
  transition-property: box-shadow;
}

.glow:hover {
  box-shadow: 0 0 8px grey;
}

/* Hover Shadow */
@keyframes hover {
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(-6px);
  }
}
@keyframes hover-shadow {
  0% {
    transform: translateY(6px);
    opacity: 0.4;
  }
  50% {
    transform: translateY(3px);
    opacity: 1;
  }
  100% {
    transform: translateY(6px);
    opacity: 0.4;
  }
}
.hover-shadow {
  display: inline-block;
  position: relative;
  transition-duration: 0.3s;
  transition-property: transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hover-shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  /* W3C */
  transition-duration: 0.3s;
  transition-property: transform opacity;
}
.hover-shadow:hover {
  transform: translateY(-6px);
  animation-name: hover;
  animation-duration: 1.5s;
  animation-delay: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.hover-shadow:hover:before {
  opacity: 0.4;
  transform: translateY(6px);
  animation-name: hover-shadow;
  animation-duration: 1.5s;
  animation-delay: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

/* Float */
.float {
  display: inline-block;
  transition-duration: 0.3s;
  transition-property: transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
}
.float:hover {
  transform: translateY(-5px);
}

/* Drag and Drop Images */

.drag-and-drop-item {
  cursor: grab;
}

/* Effect Box Shadow */
.box_shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

