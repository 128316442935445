/* Position center flex */

.position_center {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.absolute {
  position: absolute;
}