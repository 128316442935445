.container-border-rounded {
  border: solid 2px var(--color-blue);
  border-radius: 10px;
}

.container_filter_product {
  background-color: var(--color-grey-light);
  border-radius: 10px;
}



/* Container grey */

.container-grey {
  background-color: #F5F5F5;
  
}


.background-white {
  background-color: white;
overflow: auto;
display: block;
}
.background-blue-light {
  background-color: var(--color-blue-light);
overflow: auto;
display: block;
}
.background-red {
  background-color: var(--color-red);
overflow: auto;
display: block;
}
.background-red-opacity {
  background-color: var(--color-red-opacity);
overflow: auto;
display: block;
}

.background-2{
  background-color: rgba(140, 204, 228, 0.5);
}

/* Circle */

.circle {
  background-color: var(--color-blue);
  border-radius: 100%;
  height: 22px;
  width: 22px;
  text-align: center;
}


.circle p {
  vertical-align: middle;
margin-top: 0.10em;
  font-size: 12px;
  font-weight: bold;
  font-family: sans-serif;
  color: white;
}