.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  margin-bottom: 30px;
  border: none;
  outline: none;
  background: transparent;
}

.login-box.yellow .user-box input {
  color: var(--color-yellow);
  border-bottom: 1px solid var(--color-yellow);
}
.login-box.blue .user-box input {
  color: var(--color-blue);
  border-bottom: 1px solid var(--color-blue);
}
.login-box.blue-light .user-box input {
  color: var(--color-blue-light);
  border-bottom: 1px solid var(--color-blue-light);
}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}
.login-box.yellow .user-box label {
  color: var(--color-yellow);
}
.login-box.blue .user-box label {
  color: var(--color-blue);
}
.login-box.blue-light .user-box label {
  color: var(--color-blue-light);
}

.login-box .user-box input ~ label{
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.login-box.yellow .user-box input ~ label{
  color: var(--color-yellow);
}

.login-box.blue .user-box input ~ label{
  color: var(--color-blue);
}
.login-box.blue-light .user-box input ~ label{
  color: var(--color-blue-light);
}

.login-box .user-box input:focus ~ label {
  top: -20px;
  left: 0;
  font-size: 12px;
}
.login-box.yellow .user-box input:focus ~ label {
  color: var(--color-yellow);
}
.login-box.blue .user-box input:focus ~ label {
  color: var(--color-blue);
}
.login-box.blue-light .user-box input:focus ~ label {
  color: var(--color-blue-light);
}

/* .login-box .user-box input:invalid ~ label {
    top: 0;
    left: 0;
    color: white;
    font-size: 16px;
  } */

.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  font-size: 12px;
}

.login-box.yellow .user-box input:valid ~ label {
  color: var(--color-yellow);
}

.login-box.blue .user-box input:valid ~ label {
  color: var(--color-blue);
}
.login-box.blue-light .user-box input:valid ~ label {
  color: var(--color-blue-light);
}

/* 
  .login-box .user-box input:invalid ~ label {
    top: -20px;
    left: 0;
    color: red;
    font-size: 16px;
  } */

/* Autofill */
input:-internal-autofill-selected {
  background-color: red;
}
