/* Fonts */

body {
  font-family: 'Noto Sans', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'Rakkas';
}

/* Colors */
.yellow {
  color: var(--color-yellow);
}

.white {
  color: white;
}

.blue {
  color: var(--color-blue);
}
.red {
  color: var(--color-red);
}

.blue-light {
  color: var(--color-blue-light);
}
.black {
  color: black;
}

/* Font Weight */
.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 400;
}

.extra-bold {
  font-weight: 800;
}

.light {
  font-weight: 300;
}

/* Font size */
.title {
  font-size: 5em;
}
@media (max-width: 400px) {
  .title {
    font-size: 2em;
  }
}
/* Other */
.text-shadow {
  text-shadow: -2px 2px 5px rgba(0, 0, 0, 100%);
}
